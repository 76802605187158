import React, { useState } from "react"
import "../assets/styles/_index.scss"
import {
  ArrowLeft,
  ArrowRight,
  CurrencyRupee,
  GeoAlt,
} from "react-bootstrap-icons"
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "gatsby"

import prevArrow from '/utility/assets/icons/prevArrow.png'
import nextArrow from '/utility/assets/icons/nextArrow.png'

import Brochureimage from "/utility/assets/common/details/brochure-image.png"
import Floorplanimage from "/utility/assets/common/details/floor-plan-image.png"
import Projectpriceimage from "/utility/assets/common/details/project-price-image.png"
import Projectvideoimage from "/utility/assets/common/details/project-video-image.png"

import MaaHomesEcvostoneLogo from "/utility/assets/maa-homes/our-properties/maa-SubhaEcostone.png"

import VideoPlayerTv from "../../../videoplayerTv/VideoPlayerTv"
import FileModal from "../../../filemodal/FileModal"
import DownloadFileModal from "../../../downloadfilemodal/DownloadFileModal"

const videoUrl = "https://www.propverse.ai/utility/assets/maa-homes/Ecostone/EcostoneVillaVideo.mp4"
const thumbnailUrl = require("/utility/assets/common/prop-tv-image.png")

const CustomPrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        width: "5px",
        height: "5px",
        backgroundImage: `url(${prevArrow})`,
      }}
      onClick={onClick}
    />
  )
}

const CustomNextArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        width: "5px",
        height: "5px",
        backgroundImage: `url(${nextArrow})`,
      }}
      onClick={onClick}
    />
  )
}

const MaaHomesEcostoneSidebar = ({ isOpen, toggleRightSidebar, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownlaodModalOpen, setIsDownloadModalOpen] = useState(false);

  const openDownloadModal = () => {
    setIsDownloadModalOpen(true)

    
  };

  const closedownloadModal = () => {
    setIsDownloadModalOpen(false)
  };


  const openModal = () => {
    setIsModalOpen(true);
    
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };





  const handleFloorViewButtonClick = () => {
    openModal()  }

  const handleFloorDownloadButtonClick = () => {
    openDownloadModal()
  }

  const handleBrochureViewButtonClick = () => {
    window.open("/utility/assets/maa-homes/Ecostone/ECOSTONEBROCHURE.pdf")
  }

  const handleBrochureDownloadButtonClick = () => {
    const pdfUrl = "/utility/assets/maa-homes/Ecostone/ECOSTONEBROCHURE.pdf"

    const anchor = document.createElement("a")
    anchor.href = pdfUrl
    anchor.download = "ECOSTONEBROCHURE.pdf"
    anchor.click()
  }

  const handleVideoViewButtonClick = () => {
    window.open("/utility/assets/maa-homes/Ecostone/EcostoneVillaVideo.mp4")
  }

  const handleVideoDownloadButtonClick = () => {
    const videoUrl = "/utility/assets/maa-homes/Ecostone/EcostoneVillaVideo.mp4"
    const anchor = document.createElement("a")
    anchor.href = videoUrl
    anchor.download = "EcostoneVillaVideo.mp4"
    anchor.click()
  }

  const handlePriceViewButtonClick = () => {
    window.open("/utility/assets/maa-homes/Ecostone/EcostonePriceSheet.pdf")
  }

  const handlePriceDownloadButtonClick = () => {
    const imageUrl = "/utility/assets/maa-homes/Ecostone/EcostonePriceSheet.pdf"
    const anchor = document.createElement("a")
    anchor.href = imageUrl
    anchor.download = "EcostonePriceSheet.pdf"
    anchor.click()
  }

  const cardItems = [
    {
      id: 0,
      image: Brochureimage,
      title: "Brochure",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/bloom-dell/BirlaBrochure.pdf",
      viewButtonClick: handleBrochureViewButtonClick,
      downloadButtonClick: handleBrochureDownloadButtonClick,
    },
    {
      id: 1,
      image: Projectvideoimage,
      title: "Project Video",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/bloom-dell/BirlaAlokya.mp4",
      viewButtonClick: handleVideoViewButtonClick,
      downloadButtonClick: handleVideoDownloadButtonClick,
    },
    {
      id: 2,
      image: Projectpriceimage,
      title: "Project Price",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/bloom-dell/PriceList.jpg",
      viewButtonClick: handlePriceViewButtonClick,
      downloadButtonClick: handlePriceDownloadButtonClick,
    },
    {
      id: 3,
      image: Floorplanimage,
      title: "Floor plan",
      button1: "Download",
      button2: "View",
      viewButtonClick: handleFloorViewButtonClick,
      downloadButtonClick: handleFloorDownloadButtonClick,
      BirlaAlokya:
        "/utility/assets/birla-estates/bloom-dell/floor-plan/FloorPlan-0.jpg",
    },
  ]

  const files = [
    { name: 'EAST_FACING_VILLA_30x40_03.08.2022', type: 'jpg', url: '/utility/assets/maa-homes/Ecostone/FloorPlan/EAST_FACING_VILLA_30x40_03.08.2022.jpg' },
    { name: 'EAST_FACING_VILLA1_30x50_03.08.2022', type: 'jpg', url: '/utility/assets/maa-homes/Ecostone/FloorPlan/EAST_FACING_VILLA1_30x50_03.08.2022.jpg' },
    { name: 'NORTH_FACING_VILLA_30x40_03.08.2022', type: 'jpg', url: '/utility/assets/maa-homes/Ecostone/FloorPlan/NORTH_FACING_VILLA_30x40_03.08.2022.jpg' },
    { name: 'NORTH_FACING_VILLA1_30x50_03.08.2022', type: 'jpg', url: '/utility/assets/maa-homes/Ecostone/FloorPlan/NORTH_FACING_VILLA1_30x50_03.08.2022.jpg' },
    { name: 'SOUTH_FACING_VILLA_30x40_03.08.2022', type: 'jpg', url: '/utility/assets/maa-homes/Ecostone/FloorPlan/SOUTH_FACING_VILLA_30x40_03.08.2022.jpg' },
    { name: 'WEST_FACING_VILLA_30x50_03.08.2022', type: 'jpg', url: '/utility/assets/maa-homes/Ecostone/FloorPlan/WEST_FACING_VILLA_30x50_03.08.2022.jpg' },
  ];

  const hideButton = id === "5"
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  }

  return (
    <div className={`right-sidebar-details ${isOpen ? "open" : ""}`}>
      <div className="projectdetailsBackButton">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="mt-2">
                <span onClick={toggleRightSidebar}>
                  <Link to="/developers/maa-homes-developer/">
                    <ArrowLeft /> Back
                  </Link>
                </span>
              </div>
            </Col>
            <Col lg={6}>
              <h5 className="m-0">Ecostone</h5>
            </Col>
            <Col lg={3}>
              <Image
                src={MaaHomesEcvostoneLogo}
                fluid
                className="headerImage m-0"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="projectdetailslist">
        {cardItems.map(card => (
          <div>
            <Container>
              <Col>
                <Row lg={2}>
                  <h6 className="projectdetailslist-card-title">
                    {card.title}
                  </h6>
                </Row>
                <Row lg={8}>
                  <Image src={card.image} alt="2" />
                </Row>
                {card.id !== 4 && (
                  <Row lg={2} className="projectdetailslist-card-buttons">
                    <Button
                      className="card-buttons-download"
                      onClick={card.downloadButtonClick}
                    >
                      {card.button1}
                    </Button>
                    <Button
                      className="card-buttons-view"
                      onClick={card.viewButtonClick}
                    >
                      {card.button2}
                    </Button>
                  </Row>
                )}
              </Col>
            </Container>
          </div>
        ))}
        <div className="projectdetailslist-tv">
          <h6 className="projectdetailslist-tv-title">Prop TV</h6>

          <VideoPlayerTv
            className="projectdetailslist-tv-video"
            videoUrl={videoUrl}
            thumbnailUrl={thumbnailUrl}
          />
        </div>
        <div className='footer-link'>
          <Link target="_blank" to="https://www.propex.ai/projects/np-62536-subha-ecostone/colive_ppm_52536">
            <text>Click here for more details<ArrowRight /> </text>
          </Link>

        </div>
      </div>


      {isModalOpen && (
        <FileModal isOpen={isModalOpen} onRequestClose={closeModal} files={files} />
      )}
          {isDownlaodModalOpen && (
        <DownloadFileModal isOpen={isDownlaodModalOpen} onRequestClose={closedownloadModal} files={files} />
      )}
    </div>
  )
}

export default MaaHomesEcostoneSidebar
